import React, {useState, useEffect} from 'react';
import {Select, MenuItem} from '@mui/material';
import {useNavigate, useLocation} from 'react-router-dom';
import '../styles/MobileNavigationSelect.css';

function MobileNavigationSelect()
{
    const navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = useState('');

    useEffect(() =>
    {
        if (location.pathname === '/')
        {
            setPage('');
        }
    }, [location]);

    const handleChange = (event) =>
    {
        const destination = event.target.value;
        setPage(destination);
        navigate(destination);
    };

    return (
        <Select
            value={page}
            onChange={handleChange}
            displayEmpty
            inputProps={{'aria-label': 'Without label'}}
            sx={{
                color: 'white', // Styles pour le bouton
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Suppression de la bordure
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Assurez-vous que la bordure reste supprimée même au survol
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Assurez-vous que la bordure reste supprimée lors de la mise au point sur le sélecteur
                },
                '.MuiSvgIcon-root': {color: 'white'}, // Icone de flèche en blanc
            }}
            MenuProps={{
                classes: {paper: 'glassmorphismMenu'} // Appliquer la classe CSS au menu
            }}
        >
            <MenuItem value="" disabled>Offres</MenuItem>
            <MenuItem value="/discovery">Découverte</MenuItem>
            <MenuItem value="/student">Etudiant</MenuItem>
            <MenuItem value="/pro">Professionnel</MenuItem>
        </Select>
    );
}

export default MobileNavigationSelect;
