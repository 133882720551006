import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import OfferCard from './OfferCard';
import HomeLogo from "./HomeLogo";
import HomeText from "./HomeText";
import TrustSection from "./HomeTrust";
import TeamSection from "./HomeTeam"; // Assurez-vous que le chemin d'importation est correct

const offers = [
    {
        id: 1,
        iconName: "cloud-outline",
        title: "Découverte",
        description: "Créez un mini-portfolio simple et élégant accessible via une lien personnalisé !",
        url: "/discovery",
    },
    {
        id: 2,
        iconName: "school-outline",
        title: "Étudiant",
        description: "Association ou étudiant ? Votre site vitrine hébergé en 3 clics et à prix d'étudiant !",
        url: "/student",
    },
    {
        id: 3,
        iconName: "briefcase-outline",
        title: "Professionnel",
        description: "Besoin d'un site de gestion, de vente ou de réservation ? Faites appel à nous !",
        url: "/pro",
    },
];


function HomePage()
{
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box>
            <HomeLogo/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: 'fit-content',
                minHeight: 'calc(var(--window-height)*0.75)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Box sx={{
                    display: 'flex', // Utilisez flex ici
                    flexDirection: 'column', // Aligner les éléments horizontzalement
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '90%', // Assurez-vous que c'est suffisamment large
                    maxWidth: {xs: '345px', sm: 'calc(var(--app-width))'},
                    textAlign: 'center',
                }}>

                    <HomeText/>
                    <TeamSection/>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: {xs: 'wrap', md: 'nowrap'},
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2,
                        gap: 2, // Espacement entre les cards
                        backgroundColor: 'transparent', // Box conteneur transparent
                    }}>
                        {offers.map(offer => (
                            <OfferCard
                                key={offer.id}
                                iconName={offer.iconName}
                                title={offer.title}
                                description={offer.description}
                                url={offer.url}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
            <TrustSection/>
        </Box>
    );
}

export default HomePage;
