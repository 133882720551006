import React, {useEffect} from 'react';
import {Box, Card, CardContent, CardMedia, Typography, List, ListItem} from '@mui/material';
import "../styles/common.css";
import {motion} from 'framer-motion';

function Student()
{
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    const title = "Etudiant";
    const subtitle = "Vous. Mais pas que.";
    const descriptions = [
        "Envie de faire décoller votre projet web sans vous ruiner ni vous compliquer la vie ? Quill est là pour vous ! Avec notre offre étudiante, lancez votre site vitrine ou associatif en un clin d’œil, directement depuis votre GitHub. Pas de tracas, pas de documentation à éplucher, juste votre créativité à l'œuvre.",
        "C'est l'idéal pour les étudiants en informatique, les associations en quête d'une présence web efficace, ou tout simplement pour ceux qui veulent partager leurs projets sans se prendre la tête.",
        "Vous avez un nom de domaine en tête ? Quill.ws vous accueille à bras ouverts, ou laissez-vous tenter par un nom de domaine personnel pour marquer votre territoire numérique. Et pour les curieux de savoir combien de visiteurs admirent leur travail, notre outil d'administration dévoile tout."
    ];
    // const price = "8€/an";
    const imageUrl = "/assets/student.png";

    return (
        <Box className="centered-window second-color">
            <motion.div
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                <Card className={"card-custom"} sx={{
                    maxWidth: {xs: '345px', sm: 'calc(var(--app-width) - 2em)'},
                    background: 'var(--glass-background)',
                    backdropFilter: 'blur(9.6px)',
                    borderRadius: 'var(--border-radius)',
                    padding: '1em',
                    color: 'var(--white)',
                    boxShadow: 'var(--glass-shadow)',
                }}>
                    <motion.div
                        initial={{opacity: 0, y: -90}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    >
                        <CardMedia
                            component="img"
                            image={imageUrl}
                            alt="Image"
                        />
                    </motion.div>
                    <CardContent>
                        <Typography gutterBottom variant="h3" component="div">
                            {title}
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div">
                            {subtitle}
                        </Typography>
                        {descriptions.map((paragraph, index) => (
                            <Typography
                                key={index}
                                variant="body1"
                                component="div"
                                sx={{
                                    marginTop: '1em',
                                    display: {xs: index === 0 ? 'block' : 'none', sm: 'block'}
                                }}
                            >
                                {paragraph}
                            </Typography>
                        ))}
                        <Typography variant="h6" component="div" sx={{marginTop: '2em'}}>
                            Caractéristiques principales
                        </Typography>
                        <List>
                            <ListItem>
                                <ion-icon name="globe-outline"></ion-icon>
                                Nom de domaine .quill.ws ou utilisez votre propre nom de domaine (non inclus).
                            </ListItem>
                            <ListItem>
                                <ion-icon name="cloud-upload-outline"></ion-icon>
                                Déploiement automatique du code depuis GitHub en un clic.
                            </ListItem>
                            <ListItem>
                                <ion-icon name="stats-chart-outline"></ion-icon>
                                Suivez la popularité de votre site avec un outil d'administration dédié.
                            </ListItem>
                            <ListItem>
                                <ion-icon name="code-slash-outline"></ion-icon>
                                Supporte HTML/CSS/JS pour des sites vitrines impeccables et statiques.
                            </ListItem>
                        </List>
                        <Typography gutterBottom variant="h6" component="div" sx={{
                            pt: 2,
                            color: 'var(--grey)',
                        }}>
                            Bientôt disponible !
                        </Typography>
                    </CardContent>
                </Card>
            </motion.div>
        </Box>
    );
}

export default Student;
