import {Box, Typography} from "@mui/material";
import React from "react";

function HomeText()
{
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'}, // Empiler sur les petits écrans, côte à côte sur les plus grands
            alignItems: 'center',
            flexWrap: {xs: 'wrap', md: 'nowrap'},
            justifyContent: 'center',
            maxHeight: 'var(--window-height)',
            minHeight: 'calc(var(--window-height)*0.5)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            p: 4,
        }}>
            <Box sx={{
                width: {sm: 'calc(var(--app-width) * 0.55)', xs: '80vw'}, // Demi-largeur sur les écrans plus grands
                textAlign: 'left',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Typography variant="h6" sx={{
                    color: 'var(--white)',
                    marginBottom: 2,
                    textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                    fontSize: {xs: '1.2rem', sm: '1.3rem', md: '1.4rem'}, // Taille de police ajustable
                }}>
                    Quill, votre rampe de lancement numérique — que vous soyez un <b>étudiant</b> ou
                    une <b>association</b> à la recherche d'une
                    présence en ligne à <b>prix abordable</b> ou <b>professionnel</b> en quête d'une solution
                    d'hébergement <b>fiable</b>. De la conception intuitive de portfolios à la <b>gestion avancée de
                    sites
                    professionnels</b>, notre plateforme est le tremplin de chaque ambition numérique.
                </Typography>
            </Box>
            <Box sx={{
                width: {sm: 'calc(var(--app-width) * 0.45)'}, // Demi-largeur sur les écrans plus grands
                textAlign: 'right', // Alignement à droite pour la citation
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Typography variant="h4" sx={{
                    color: 'var(--white)',
                    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
                    fontSize: {xs: '1rem', sm: '1.8rem'}, // Taille de police ajustable
                }}>
                    "Léger comme une plume, robuste comme votre ambition."
                </Typography>
            </Box>
        </Box>
    );
}

export default HomeText;