import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import '../styles/Home.css';

const brandLogos = [
    {
        'picture': '/assets/sebastien.jpeg',
        'name': 'Sébastien'
    },
    {
        'picture': '/assets/thimot.jpeg',
        'name': 'Thimot'
    },
    {
        'picture': '/assets/thomas.png',
        'name': 'Thomas'
    },
    {
        'picture': '/assets/matteo.jpeg',
        'name': 'Mattéo'
    },
    {
        'picture': '/assets/baptiste.jpeg',
        'name': 'Baptiste'
    },
    {
        'picture': '/assets/romain.jpeg',
        'name': 'Romain'
    },
    {
        'picture': '/assets/rebecca.jpeg',
        'name': 'Rebecca'
    },
    {
        'picture': '/assets/dorian.jpeg',
        'name': 'Dorian'
    },
];

function TeamSection()
{
    return (
        <Box id={"trusted"} sx={{
            textAlign: 'center',
            py: 5,
            width: {xs: '100%', sm: 'var(--app-width)'},
            minHeight: '20em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
        }}>
            <Typography variant="h5" sx={{mb: 3, color: 'var(--white)'}}>
                Les visages derrière Quill et Quill Pro
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: {xs: 'row', sm: 'row'},
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                gap: 1
            }}>
                {brandLogos.map((image, index) => (
                    <Grid item xs={4} sm={2} key={index}>
                        <Box
                            component="img"
                            src={image.picture}
                            alt={`Logo de la marque ${index + 1}`}
                            sx={{
                                borderRadius: '50%',
                                maxWidth: '100px',
                                height: '100px',
                                boxShadow: 'var(--glass-shadow)',
                            }}
                        />
                        <Typography variant="body1" sx={{color: 'var(--white)'}}>{image.name}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default TeamSection;
