import React from 'react';
import {Box, Typography, Container, Grid, Link} from '@mui/material';
import "../styles/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import {LinkedIn} from "@mui/icons-material";

const Footer = () =>
{
    return (
        <Box component="footer" className={"footer"}>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                        <Link href="/" style={{textDecoration: 'none'}}>
                            Accueil
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/discovery" style={{textDecoration: 'none'}}>
                            Découverte
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/student" style={{textDecoration: 'none'}}>
                            Etudiant
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/pro" style={{textDecoration: 'none'}}>
                            Professionnel
                        </Link>
                    </Grid>

                </Grid>
                <Box textAlign="center" padding={2}>
                    <Box display="flex" justifyContent="center" alignItems="center" gap={6}>
                        <Link href="mailto:contact@quill.ws" color="inherit">
                            <EmailIcon/>
                        </Link>
                        <Link href="https://www.instagram.com/quill.ws" color="inherit">
                            <InstagramIcon/>
                        </Link>
                        <Link href="https://www.linkedin.com/company/quill-ws" color="inherit">
                            <LinkedIn/>
                        </Link>
                    </Box>
                </Box>
                <Typography variant="body2" align="center" marginTop={0}>
                    © {new Date().getFullYear()} Quill - Tous droits réservés.
                </Typography>
            </Container>

        </Box>
    );
};


export default Footer;
