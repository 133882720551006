import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import '../styles/Home.css';

const brandLogos = [
    '/assets/lanor.jpg',
    '/assets/dadsonprod.png',
    '/assets/gosafe.png',
    '/assets/cagnas39.png'
];

function TrustSection()
{
    return (
        <Box id={"trusted"} sx={{
            textAlign: 'center',
            py: 5,
            width: {xs: '100%', sm: 'var(--app-width)'},
            minHeight: '20em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
        }}>
            <Typography variant="h5" sx={{mb: 3, color: 'var(--white)'}}>
                Ils nous font confiance !
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: {xs: 'column', sm: 'row'},
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                gap: 2
            }}>
                {brandLogos.map((logoSrc, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Box
                            component="img"
                            src={logoSrc}
                            alt={`Logo de la marque ${index + 1}`}
                            sx={{
                                borderRadius: 'var(--border-radius)',
                                maxWidth: '250px',
                                height: '100px',
                                boxShadow: 'var(--glass-shadow)',
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default TrustSection;
