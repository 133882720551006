import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Hidden,
    createTheme,
    ThemeProvider
} from '@mui/material';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import MobileNavigationSelect from '../components/MobileNavigationSelect';
import {motion} from 'framer-motion';

import Home from "../components/Home";
import Discovery from "../components/Discovery";
import Footer from "../components/Footer";
import Student from "../components/Student";
import Pro from "../components/Pro";

import '../styles/App.css';


const theme = createTheme({
    typography: {
        fontFamily: [
            'Cantarell',
        ].join(','),
    },
});

function App()
{
    return (
        <motion.div
            initial={{opacity: 0, y: -50}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 1}}
        >
            <ThemeProvider theme={theme}>
                <Router>
                    <div className="App">
                        <AppBar className="app-bar-container" position="sticky" sx={{
                            marginTop: '1em',
                            boxShadow: '0 8px 32px 0 rgba(0, 1, 2, 0.10)',
                            background: 'rgba(255, 255, 255, 0.3)',
                        }}>
                            <Toolbar className="app-bar">
                                <Typography variant="h6" style={{flexGrow: 1}}>
                                    <Link to="/" style={{textDecoration: 'none', color: 'inherit'}}>
                                        QUILL
                                    </Link>
                                </Typography>
                                <Hidden smDown>
                                    <Button color="inherit" component={Link} to="/discovery">Découverte</Button>
                                    <Button color="inherit" component={Link} to="/student">Etudiant</Button>
                                    <Button color="inherit" component={Link} to="/pro">Professionnel</Button>
                                </Hidden>
                                <Hidden smUp>
                                    <MobileNavigationSelect/>
                                </Hidden>
                            </Toolbar>
                        </AppBar>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/discovery" element={<Discovery/>}/>
                            <Route path="/student" element={<Student/>}/>
                            <Route path="/pro" element={<Pro/>}/>
                        </Routes>
                    </div>
                    <Footer/>
                </Router>
            </ThemeProvider>
        </motion.div>
    );
}

export default App;
