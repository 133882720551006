import React, {useEffect} from 'react';
import {Box, Card, CardContent, CardMedia, Typography, Button, List, ListItem} from '@mui/material';
import "../styles/common.css";
import {motion} from 'framer-motion';

function Discovery()
{
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);

    const title = "Portfolio";
    const subtitle = "Vous. Votre image en ligne.";
    const descriptions = [
        "Lancez-vous dans la création de votre portfolio numérique avec Quill, sans dépenser un centime ! Parfait pour les étudiants ambitieux qui cherchent à se faire remarquer avec style. Nous savons combien il est crucial de se démarquer pour décrocher ce stage ou ce premier emploi de rêve, et nous sommes là pour faciliter ce pas.",
        "Choisissez parmi une gamme de thèmes chics, présentez vos réalisations en un clin d'œil, et Quill orchestrera le reste. Avec votre propre URL et une visibilité améliorée sur Google, faites briller vos compétences devant les recruteurs et les professeurs. Et qui sait ? Peut-être même éveiller l'intérêt au-delà des frontières !",
        "Avec Quill Portfolio, alliez simplicité et professionnalisme pour bâtir dès à présent votre identité numérique."
    ];
    const price = "Gratuit";
    const imageUrl = "/assets/discovery.png";

    return (
        <Box className="centered-window second-color">
            <motion.div
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                <Card className={"card-custom"} sx={{
                    maxWidth: {xs: '345px', sm: 'calc(var(--app-width) - 2em)'},
                    background: 'var(--glass-background)',
                    borderRadius: 'var(--border-radius)',
                    backdropFilter: 'blur(9.6px)',
                    padding: '1em',
                    color: 'var(--white)',
                    boxShadow: 'var(--glass-shadow)',
                }}>
                    <motion.div
                        initial={{opacity: 0, y: -90}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    >
                        <CardMedia
                            component="img"
                            image={imageUrl}
                            alt="Image"
                        />
                    </motion.div>
                    <CardContent>
                        <Typography gutterBottom variant="h3" component="div">
                            {title}
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div">
                            {subtitle}
                        </Typography>
                        {descriptions.map((paragraph, index) => (
                            <Typography
                                key={index}
                                variant="body1"
                                component="div"
                                sx={{
                                    marginTop: '1em',
                                    display: {xs: index === 0 ? 'block' : 'none', sm: 'block'}
                                }}
                            >
                                {paragraph}
                            </Typography>
                        ))}

                        <Typography variant="h6" component="div" sx={{marginTop: '2em'}}>
                            Caractéristiques principales
                        </Typography>
                        <List>
                            <ListItem>
                                <ion-icon name="extension-puzzle-outline"></ion-icon>
                                Aucune compétence technique requise
                            </ListItem>
                            <ListItem>
                                <ion-icon name="link-outline"></ion-icon>
                                URL personnalisée en .quill.ws
                            </ListItem>
                            <ListItem>
                                <ion-icon name="search-outline"></ion-icon>
                                Référencement Google optimisé
                            </ListItem>
                            <ListItem>
                                <ion-icon name="color-fill-outline"></ion-icon>
                                Thèmes élégants et variés
                            </ListItem>
                            <ListItem>
                                <ion-icon name="wallet-outline"></ion-icon>
                                Offre entièrement gratuite
                            </ListItem>
                        </List>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            pt: 2
                        }}>
                            <Typography variant="h6" component="div" sx={{
                                color: 'var(--grey)',
                            }}>
                                {price}
                            </Typography>
                            <Button variant="contained" href="https://app.quill.ws" sx={{
                                background: 'var(--glass-background)',
                                color: 'var(--white)',
                                boxShadow: 'var(--glass-shadow)',
                                borderRadius: 'calc(var(--border-radius) - 5px)',
                                '&:hover': {
                                    background: 'var(--glass-background-hover)',
                                    boxShadow: 'var(--glass-shadow)',
                                }
                            }}>
                                Accéder à l'app !
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </motion.div>
        </Box>
    );
}

export default Discovery;
