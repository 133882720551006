import {Box, Typography} from "@mui/material";
import {motion} from "framer-motion";
import React from "react";


function HomeLogo(props)
{
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 'var(--window-height)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <Box sx={{
                display: 'flex', // Utilisez flex ici
                flexDirection: 'column', // Aligner les éléments horizontalement
                alignItems: 'center',
                justifyContent: 'center',
                width: '90%', // Assurez-vous que c'est suffisamment large
                maxWidth: {xs: '345px', sm: 'calc(var(--app-width) - 4em)'},
                textAlign: 'center',
            }}>
                <Box sx={{
                    marginBottom: '2em',
                }}>
                    <motion.div
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 1}}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 200,
                                width: 200,
                                borderRadius: '50%',
                                boxShadow: '0px 30px 60px rgba(0, 0, 0, 0.25)',
                            }}
                            alt="Votre logo"
                            src="/assets/logo-bgcolor-app.png"
                        />
                    </motion.div>

                </Box>
                <Box>
                    <motion.div
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    >
                        <Typography variant="h4" sx={{
                            color: 'var(--white)',
                            mt: 2,
                            textShadow: '2px 2px 8px rgba(0,0,0,0.2)',
                        }}>
                            QUILL
                        </Typography>
                        <Typography variant="h7" sx={{
                            color: 'var(--grey)',
                            my: 2,
                            display: {xs: 'none', sm: 'block'},
                            textShadow: '1px 1px 4px rgba(0,0,0,0.1)',
                        }}>
                            Léger comme une plume, robuste comme votre ambition.
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: 'var(--white)',
                            mt: {xs: 5, sm: 0},
                            mb: {xs: 0, sm: 2},
                            fontSize: {xs: '1.2em', sm: '1.5em'},
                            display: {xs: 'block', sm: 'block'},
                            textShadow: '2px 2px 8px rgba(0,0,0,0.2)',
                        }}>
                            Le web, vu par des ingénieurs nouvelle génération.
                        </Typography>
                    </motion.div>
                </Box>
            </Box>
        </Box>
    );
}

export default HomeLogo;