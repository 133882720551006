import React from 'react';
import {Card, CardContent, Typography, Button} from '@mui/material';
import {motion} from 'framer-motion';

function OfferCard({iconName, title, description, url})
{
    const iconHTML = `<ion-icon name="${iconName}" style="font-size: 60px; color: var(--white); margin: auto;"></ion-icon>`;

    return (
        <motion.div
            initial={{opacity: 0, y: -50}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'calc(var(--app-width)/3 - 1.65em)',
                background: 'var(--glass-background)',
                backdropFilter: 'blur(9.6px)',
                borderRadius: 'var(--border-radius)',
                padding: '0.5em',
                color: 'var(--white)',
                boxShadow: 'var(--glass-shadow)',
            }}>
                <div dangerouslySetInnerHTML={{__html: iconHTML}}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="var(--grey)">
                        {description}
                    </Typography>
                    <Button variant="contained" sx={{
                        mt: 2,
                        borderRadius: 'calc(var(--border-radius) - 5px)',
                        background: 'var(--glass-background)',
                        color: 'var(--white)',
                        boxShadow: 'var(--glass-shadow)',
                        '&:hover': {
                            background: 'var(--glass-background-hover)',
                            boxShadow: 'var(--glass-shadow)',
                        }
                    }}
                            href={url}
                    >
                        En savoir plus
                    </Button>
                </CardContent>
            </Card>
        </motion.div>
    );
}

export default OfferCard;