import React, {useEffect} from 'react';
import {Box, Card, CardContent, Typography, List, ListItem} from '@mui/material';
import "../styles/common.css";
import {motion} from 'framer-motion';


function Pro()
{
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    const descriptions = [
        "Quill Professionnel s'adresse à tous les stades de votre projet web : du développement initial à l'hébergement sécurisé. Que vous souhaitiez transformer une idée en un site web entièrement fonctionnel ou simplement héberger et superviser un projet existant, notre offre s'adapte à vos besoins. Bénéficiez d'une solution personnalisée qui englobe la conception, le développement, l'hébergement, la supervision et la sécurité pour garantir une présence en ligne optimale et sans tracas.",
        "Notre service d'hébergement est bâti sur une infrastructure robuste, complétée par une surveillance proactive pour assurer une performance optimale. Choisissez notre solution tout-en-un pour une prise en charge complète de votre projet, depuis sa conception jusqu'à son déploiement, et profitez d'un accompagnement professionnel à chaque étape.",
        "Avec Quill, engagez-vous dans une collaboration qui mettra votre vision au premier plan, offrant un accompagnement sur mesure et une flexibilité totale pour que votre projet web atteigne ses objectifs avec succès."
    ];
    return (
        <Box className="centered-window second-color">
            <motion.div
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                <Card className={"card-custom"} sx={{
                    maxWidth: {xs: '345px', sm: 'calc(var(--app-width) - 2em)'},
                    background: 'var(--glass-background)',
                    backdropFilter: 'blur(9.6px)',
                    borderRadius: 'var(--border-radius)',
                    padding: '1em',
                    color: 'var(--white)',
                    boxShadow: 'var(--glass-shadow)',
                }}>
                    <CardContent className="card-content-custom">
                        <Typography gutterBottom variant="h3" component="div">
                            Professionnel
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div">
                            Votre projet. Notre infra.
                        </Typography>
                        {descriptions.map((paragraph, index) => (
                            <motion.div
                                initial={{opacity: 0, y: 50}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.5}}
                            >
                                <Typography
                                    key={index}
                                    variant="body1"
                                    component="div"
                                    sx={{
                                        marginTop: '1em',
                                        display: {xs: index === 0 ? 'block' : 'none', sm: 'block'}
                                    }}
                                >
                                    {paragraph}
                                </Typography>
                            </motion.div>
                        ))}
                        <motion.div
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5}}
                        >
                            <Typography variant="h6" component="div" sx={{marginTop: '2em'}}>
                                Hébergement, supervision et sécurité
                            </Typography>
                            <List>

                                <ListItem>
                                    <ion-icon name="bar-chart-outline"></ion-icon>
                                    Infrastructure robuste et surveillance proactive
                                </ListItem>
                                <ListItem>
                                    <ion-icon name="megaphone-outline"></ion-icon>
                                    Alerting en cas d'incident ou de performance limitée</ListItem>
                                <ListItem>
                                    <ion-icon name="shield-half-outline"></ion-icon>
                                    Protection complète : DDOS, web applicative, et plus</ListItem>
                            </List>
                            <Typography variant="h6" component="div">
                                Conception et déploiement
                            </Typography>
                            <List>
                                <ListItem>
                                    <ion-icon name="color-palette-outline"></ion-icon>
                                    Proposition de design et charte graphique personnalisés
                                </ListItem>
                                <ListItem>
                                    <ion-icon name="code-slash-outline"></ion-icon>
                                    Conception et développement fullstack du projet
                                </ListItem>
                                <ListItem>
                                    <ion-icon name="cloud-upload-outline"></ion-icon>
                                    Déploiement et mise en production
                                </ListItem>
                                <ListItem>
                                    <ion-icon name="time-outline"></ion-icon>
                                    Suivi sur la durée et accompagnement continu
                                </ListItem>
                            </List>
                            <Typography gutterBottom variant="h6" component="div" sx={{
                                pt: 2,
                                color: 'var(--grey)',
                            }}>
                                Tarification sur devis
                            </Typography>
                        </motion.div>
                    </CardContent>
                </Card>
            </motion.div>
        </Box>
    );
}

export default Pro;
